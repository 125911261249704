import apiClient from '@/services/axios'

class MainService {
  async getListOfTopTabs() {
    const url = '/admin/top_tab'
    return apiClient.get(url)
  }

  async getTopTab(id) {
    const url = `/admin/top_tab/${id}`
    return apiClient.get(url)
  }

  async getTopTabLangs(id) {
    const url = `/admin/top_tab/${id}/langs`
    return apiClient.get(url)
  }

  async createTopTab(data) {
    const url = '/admin/top_tab'
    return apiClient.post(url, data)
  }

  async updateTopTab(id, data) {
    const url = `/admin/top_tab/${id}`
    return apiClient.patch(url, data)
  }

  async deleteTopTab(id) {
    const url = `/admin/top_tab/${id}`
    return apiClient.delete(url)
  }

  async duplicateSection(data, id) {
    const url = `/admin/top/${id}/copy`
    return apiClient.post(url, data)
  }
}
export default new MainService()
